import React from 'react';
import {
  Container,
} from '@mui/material';
import Header from '../../components/header/Header';

export default function Blog() {
  return (
    <Container>
      <Header />
      Storis to continue.
    </Container>
  );
}
