import React from 'react';
import { Container } from '@mui/material';
import Header from '../../components/header/Header';

export default function Articles() {
  return (
    <Container>
      <Header />
      <p>
        There is no really any story to this page.
        Cars should be baned, and this page is showing all the data why.
      </p>
    </Container>
  );
}
